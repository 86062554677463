<template>
  <v-card :class="{'grey': $vuetify.breakpoint.mdAndUp, 'lighten-4': $vuetify.breakpoint.mdAndUp}" flat
          height="100%" tile width="100%">
    <v-row justify="center" no-gutters>
      <kurcc-brand class="mt-10"/>
      <v-col class="headline my-5" cols="12">{{
          title ? title : $vuetify.lang.t('$vuetify.pages.auth.introTitle') }}
      </v-col>
      <v-col cols="12" md="10">
        <v-img contain max-height="350" src="@/assets/images/Flag-map_of_Kuwait.svg"/>
      </v-col>
      <v-col class="font-weight-bold mt-5 mb-10" cols="12" md="10">
        {{ $vuetify.lang.t('$vuetify.pages.auth.introMessage') }}
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'KurccAuthIntro',
  components: {
    KurccBrand: () => import('@/modules/app/components/KurccBrand')
  },
  props: {
    title: {
      type: String,
      required: false
    }
  }
}
</script>
